import React, { useContext, Component } from 'react';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { Link } from 'gatsby'
import { FolioWrapper, Wrapper, Overlay } from './styles'
import ToggleTheme from 'components/theme/Header/ToggleTheme';
import './styles.css'
import { render } from 'react-dom';
import { Helmet } from "react-helmet"
import { ThemeConsumer } from 'styled-components';
import img from '../../../../static/favicon/favicon-513.png'
import { Button } from "../Button"

class Folio extends Component {
    constructor(props) {
        super(props);
        this.state = { isToggleOn: true };
    }


    render() {
        const toggle = {}
        // const { theme } = useContext(ThemeContext);
        const options = {
            settings: {
                disablePanzoom: true,
            },
            buttons: {
                showDownloadButton: false,
                showFullscreenButton: false,
                showThumbnailsButton: false,
            },
            thumbnails: {
                showThumbnails: false,
            }

        }
        return (

            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{this.props.pageTitle}</title>
                </Helmet>
                <FolioWrapper>
                    <Wrapper style={{ padding: '.75rem' }}>
                        <div class="folioNav">
                            <Link to="/"><img className="brandImage" src={img} /></Link>
                            <div className="NavContainer">
                                {/* <Link className="test" to="/" activeStyle={{ "text-decoration": "underline" }}>HOME</Link> */}
                                <Link className="test" to="/travel" activeStyle={{ "text-decoration": "underline" }}>TRAVEL</Link>
                                <Link className="test" to="/portraits" activeStyle={{ "text-decoration": "underline" }}>PORTRAITS</Link>
                                <Link className="test" to="/events" activeStyle={{ "text-decoration": "underline" }}>EVENTS</Link>
                                <Link className="test" to="/aerial" activeStyle={{ "text-decoration": "underline" }}>AERIAL</Link>
                                <Link className="test" to="/urban" activeStyle={{ "text-decoration": "underline" }}>URBAN</Link>
                                <Link className="test" to="/landscapes" activeStyle={{ "text-decoration": "underline" }}>LANDSCAPES</Link>
                                {/* <ToggleTheme /> */}
                            </div>
                        </div>
                        <div>
                            <main>
                                {/* <div className='bg'>
                                    {this.props.pageTitle}
                                </div> */}
                                <Wrapper options={options}>
                                    {this.props.children}
                                </Wrapper>
                            </main>
                            <div style={{ marginTop: '25px', textAlign: "center" }}>
                                <Button onClick={() => { window.scrollTo({ top: 0, behavior: "smooth" }) }}>&#11014;</Button>
                                <p> Back to top</p>
                            </div>
                        </div>
                    </Wrapper>
                </FolioWrapper>
            </>
        );
    }
}
export default Folio