import React from "react";
import { Layout, Seo } from "components/common";
import { Intro, Skills, Contact, Projects } from "components/landing";
import { Grid } from "../components/landing/Projects/styles"
import Folio from '../components/common/folioLayout/folio'
import { StaticImage } from "gatsby-plugin-image"
import "./style.css"

export const Events = () => {
    return (
        <>
            <div class="gre">
                <Layout>
                    <Seo />
                    <Folio pageTitle="Event Pixls" underLine="events">
                        {/* <Grid> */}
                        <div class="row">
                            <div class="column">
                                <StaticImage src='../images/events/1.JPG' layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/events/2.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                            <div class="column">
                                <StaticImage src="../images/events/6.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/events/4.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/events/5.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                            <div class="column">
                                <StaticImage src='../images/events/9.JPG' layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/events/7.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/events/8.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                            <div class="column">
                                <StaticImage src="../images/events/10.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/events/11.JPG" layout="constrained" alt="Sample photo" />
                                <StaticImage src="../images/events/3.JPG" layout="constrained" alt="Sample photo" />
                            </div>
                        </div>
                        {/* </Grid> */}
                    </Folio>

                </Layout>
            </div>
        </>
    );
};


export default Events;